<template>
  <div
    class="relative p-4 border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900"
    :class="{ 'rounded-md': !tile, 'dark:!bg-neutral-800': lift }"
    @mouseenter="showDetails = true"
    @mouseleave="showDetails = false"
  >
    <div
      class="absolute inset-0 left-[20%] overflow-hidden flex before:inset-0 before:absolute before:bg-gradient-to-r before:via-50% before:from-white before:via-white/80 before:to-white/20 dark:before:from-neutral-900 dark:before:via-neutral-900/80 dark:before:to-neutral-900/20"
      :class="{
        'rounded-md': !tile,
        'dark:before:!from-neutral-800 dark:!before:via-neutral-800/80 dark:!before:to-neutral-800/20':
          lift,
      }"
    >
      <AsyncImage
        class="object-center min-h-0 w-full object-cover"
        :src="banner"
      />
    </div>
    <div class="flex gap-2 justify-between relative items-center">
      <div class="flex flex-wrap items-center gap-x-2">
        <span class="font-bold lg:text-lg">{{
          extractFromLocale(locale, tier.attributes.name)
        }}</span>
        <span
          v-if="!features.length"
          class="px-2 py-0.5 text-xs rounded-full bg-neutral-200 dark:bg-neutral-700 whitespace-nowrap"
          >{{ $t("components.subscriptionListCard.noBenefitsText") }}</span
        >
      </div>
      <span
        class="font-medium px-2 py-1 rounded-full bg-white dark:bg-neutral-800"
        >${{ tier.attributes.minAmounts.USD! / 100 }}/mo</span
      >
    </div>
    <TransitionAutoHeight
      v-if="features.length > 0 && (!noDetails || forceDetails)"
      :model-value="showDetails || !!forceDetails"
    >
      <div class="space-y-1 relative" :class="{ 'pt-2': forceDetails }">
        <div v-for="feature in features" class="text-sm sm:text-base">
          <IconPoint />{{ feature }}
        </div>
      </div>
    </TransitionAutoHeight>
  </div>
</template>

<script setup lang="ts">
import { IconPoint } from "@tabler/icons-vue";

import type { TierEntity } from "~/src/api";

const props = defineProps<{
  tier: TierEntity;
  noDetails?: boolean;
  forceDetails?: boolean;
  tile?: boolean;
  lift?: boolean;
}>();

const locale = useNuxtApp().$i18n.global.locale;
const { getTierBanner } = useMediaLink();

const showDetails = ref(false);
const banner = computed(() => getTierBanner(props.tier, "1280"));
const features = computed(() =>
  extractFromLocale(locale.value, props.tier.attributes.description)
    .split("\n")
    .filter(onlyTruthys),
);
</script>

<style scoped lang="postcss"></style>
